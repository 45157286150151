import { navigate } from 'vike/client/router'
import { register } from './register'
import { createWebSocket, needWebSocket } from './websocket'
import { useApgStore } from './useApgStore'
import {
  uuid, initialize, unload, mounted, toggle, toMediaPath, inBrowser, toObj, query, apicall, calculateBreakpoint, appInitialize,
  dmInterp, makeNavPath, setPageParams, loadProc, sendFile, videoFileSign, fileDownload, initFb, winScrollTo, setMode, isMode,
  toWebPath, setMetaOStyle, setMetaFStyle, setMetaFonts, objGetp, setMetaIcons
} from './utils'
import { meta as pageMeta } from './meta'
import { SEVAL as iSEVAL, EVAL as iEVAL } from '../../lib/mal/mal.mjs'
import { SEVAL as aSEVAL, EVAL as aEVAL, DEFENV, } from '../../lib/mal/amal.mjs'
import { getp, isString, sym, unStrQuote } from '../../lib/utils'
import { tryCompile } from '../renderer/utils'
import { applyPatches } from '../../lib/patches'
import { inRole} from './security'
import { classPrecedence } from '../../lib/gencomp/classprecedence.js'

export { createAppeggio }

function createAppeggio(vueApp, pageContext) {
  let hook = (inBrowser() && window.parent.appeggioHook || false) ? window.parent.appeggioHook : false
  applyPatches()
  let sid = uuid()
  let ws = needWebSocket(pageContext.wson) ? createWebSocket(sid, pageContext.appId, pageContext.con, pageContext.stage, pageContext.region) : false
  let store = useApgStore()
  return appeggio(vueApp, pageContext, store, sid, ws, hook)
}

function makeApiHost(host, stage) {
  if (host && host.startsWith('http://local')) return 'http://localhost:9090/local'
  if (host && host.indexOf('.lassiappeggio.com') !== -1) return 'https://api.lassiappeggio.com'
  if (host && host.indexOf('.nextappeggio.com') !== -1) return 'https://api.nextappeggio.com'
  return 'https://api.nextappeggio.com'
}

function makeApiSender(id, region) {
  return `https://${id}.lambda-url.${region}.on.aws`
}

function appeggio(vueApp, pageContext, store, sid, webSocket, hook) {

  let _host = pageContext.host
  let _appid = pageContext.appId // <- We keep initial host and appId as they change for some reason.
  let _stage = pageContext.stage
  let _slocale = pageContext.slocale
  let _editKey = false
  let _editId = false
  let _apiKey = ''
  let _appKey = ''
  let _apiHost = makeApiHost(_host, pageContext.stage)
  let _apiSender = makeApiSender(pageContext.sender, pageContext.region)
  let _ed = false

  let apg = {
    _env: DEFENV(),
    _event: false,
    vueapp() {
      return vueApp
    },
    appKey() {
      return _appKey
    },
    setAppKey(val) {
      _appKey = val
    },
    apiKey() {
      return _apiKey
    },
    setApiKey(val) {
      _apiKey = val
    },
    setEdit(id, key) {
      _editId = id
      _editKey = key
    },
    stage() {
      return _stage
    },
    isEdit() {
      return _editKey && _editId
    },
    ekeys() {
      return { id: _editId, key: _editKey }
    },
    lispenv() {
      return this._env
    },
    async le(exp) {
      let e = isString(exp) ? exp.replaceAll('|', '"') : exp
      try {
        return await (isString(e) ? aSEVAL : aEVAL)(e, this._env)
      } catch (err) {
        this.error(err.message)
      }
    },
    li(exp, dm) {
      let e = isString(exp) ? exp.replaceAll('|', '"') : exp
      if (dm) e = dmInterp(e, dm)
      try {
        return (isString(e) ? iSEVAL : iEVAL)(e, this._env)
      } catch (err) {
        this.error(err.message)
      }
    },
    page(ctx, tpl) { // TODO.JCL - remove passing in tpl
      calculateBreakpoint(this, store)
      ctx.$a = this
      if (!tpl) return pageContext.tpljs ? pageContext.tpljs(ctx) : false;
      return tryCompile(tpl)(ctx)
    },
    pageKey() {
      let tag = pageContext.ETag ? unStrQuote(pageContext.ETag) : ''
      return `${pageContext.urlPathname}${tag}`
    },
    pageVersion() {
      return store.pageVersion(this)
    },
    pagePath() {
      let it = pageContext.routeParams['*'] === 'false' ? '/' : pageContext.routeParams['*'] || pageContext.urlPathname
      return it && it.startsWith('/') ? it : `/${it}`
    },
    pageResized() {
      return store.pageResized()
    },
    pageChange() {
      this.ms('inerror', false)
      setPageParams(this, pageContext)
      store.setPageHash(pageContext)
    },
    pageTickle() {
      store.pageTickle()
    },
    registerComponents(app) {
      register(app)
    },
    unloading(cb) {
      unload(this, webSocket, cb)
    },
    mounted() {
      mounted(this, webSocket)
    },
    wsmsg(value) {
      if (value && value.l) return this.le(value.l);
    },
    toggle(key, value) {
      toggle(this, key, value)
    },
    apiRoot() {
      return _apiHost
    },
    host() {
      return _host
    },
    appId() {
      return _appid
    },
    w(obj) { // web path
      return toWebPath(this, obj)
    },
    a(obj) { // media
      return toMediaPath(this, obj)
    },
    p(obj) { // pr - props
      return obj
    },
    c(str) { // cl - class
      return str
    },
    q(aid, str) { // q - query
      return query(this, store, aid, str)
    },
    i(aid, str) {  // i - integration / api
      return apicall(this, store, aid, str)
    },
    e(event, aid, str) { // ev - event
      this._event = event
      if (event?.type === 'submit') {
        event.returnValue = false
        event.preventDefault()
        event.stopPropagation()
        this.li(str)
        return false
      }
      return this.li(str)
    },
    d(dm, key) { // d - data model value
      let it = getp(dm?.it, key)
      if (it !== undefined) return it;
      if (key === 'Index' || key === 'Idx') return dm.iti;
      if (key === 'DataLength') return dm.itc;
      return null
    },
    ms(id, val, aid) {
      store.setModel(id, val)
    },
    mg(id, dflt, aid) {
      if (dflt === undefined) return store.model(id);
      if (store.model(id) === undefined) store.setModel(id, dflt)
      return store.model(id)
    },
    mgp(id, dflt, aid) {
      return objGetp(store, id)
    },
    dModel(aid) {
      return store.model(`${aid}-${this.pageKey()}`)
    },
    clp(ucl, dcl) {
      return classPrecedence(ucl, dcl)
    },
    isWsOn() {
      return webSocket !== false
    },
    wsSid() {
      return sid
    },
    setDemoshare(tpl) {
      store.setDemoshare(tpl)
    },
    getDemoshare() {
      return store.getDemoshare()
    },
    inBrowser() {
      return inBrowser()
    },
    toObj(val) {
      return toObj(val)
    },
    clearModels(cb) {
      store.clearModels(cb)
    },
    locale() {
      return _slocale
    },
    async meta(reader) {
      return pageMeta(this, pageContext, reader)
    },
    async initialize() {
      await appInitialize(this, store, pageContext, hook)
    },
    hasUser(role, dir) {
      if (role === undefined && dir === undefined) return pageContext.user !== undefined && pageContext.user !== false;
      return inRole(role, dir, pageContext?.user?.role)
    },
    isLoading() {
      return store.isLoading()
    },
    loading() {
      store.loading()
      if (webSocket) webSocket.loading()
    },
    loaded() {
      store.loaded()
    },
    emit($e, cb) {
      if (cb) cb($e)
    },
    error(msg) {
      this.ms('error', msg)
      this.ms('inerror', true)
      debugger
    },
    nav(path, params) {
      navigate(params !== undefined ? makeNavPath(this.inBrowser(), path, params) : path)
    },
    navparams() {
      return store.navparams()
    },
    navhash() {
      return store.navhash()
    },
    isDashLayout() {
      return pageContext.urlPathname.indexOf('/priv/') !== -1 || pageContext.urlPathname.indexOf('/admin/') !== -1
    },
    hasFunc(name) {
      return this._env[sym(name)] !== undefined
    },
    user() {
      return pageContext.user || false
    },
    setNotify(id, cb) {
      store.setNotify(id, cb)
    },
    getNotify(id) {
      return store.getNotify(id)
    },
    osInfo() {
      return store.osInfo()
    },
    ddChange() {
      return store.ddChange()
    },
    setDdChange(id) {
      store.setDdChange(id)
    },
    loadProc(name) {
      return loadProc(this, store, name)
    },
    sendFile(type, url, data, isBase64) {
      return sendFile(this, type, url, data, isBase64)
    },
    winResize($e) {
      store.resized($e)
    },
    videoFileSign(file) {
      return videoFileSign(this, file)
    },
    downloadFile(path, target) {
      if (!path) return;
      let idx = path.lastIndexOf('/')
      return fileDownload(this, idx !== - 1 ? path.substring(idx + 1) : path, target)
    },
    fbInit(comp) {
      if (!inBrowser()) return;
      initFb(comp)
    },
    scrollTo(id) {
      if (!inBrowser()) return;
      winScrollTo(this, id)
    },
    mode(type) {
      setMode(this, type)
    },
    isMode(type) {
      return isMode(this, type)
    },
    usesCookies() {
      return true
    },
    isFramed() {
      return store.isFramed()
    },
    googleAnalyticsId() {
      return store.googleAnalyticsId()
    },
    getAnalyticsBooted() {
      return store.getAnalyticsBooted()
    },
    setAnalyticsBooted(val) {
      store.setAnalyticsBooted(val)
    },
    isTrackable() {
      let appid = this.appId()
      return !this.isEdit() && !this.isFramed() && appid !== 'localhost' && !appid.startsWith('dev-') && !appid.startsWith('pview-')
    },
    setEd(ed) {
      _ed = ed
    },
    setOStyles(val) {
      if (!_ed) return;
      setMetaOStyle(val)
    },
    setFStyles(val) {
      if (!_ed) return;
      setMetaFStyle(val)
    },
    setFonts(val) {
      if (!_ed) return;
      setMetaFonts(val)
    },
    setIcons(val) {
      setMetaIcons(this, val)
    },
    apiSender() {
      return _apiSender
    },
    wsConnect() {
      if (webSocket) webSocket.connect()
      else console.warn('No WebSocket on')
    }
  }
  console.log('apg - init 580')
  initialize(apg, webSocket, store, pageContext)
  // console.log('classPrecedence', classPrecedence('z-[1000]', 'z-10', true))
  return apg
}
