import { EditTexts } from './EditTexts'
import { EditColors } from './EditColors'
import { callServer } from '../app/security.js'
import { dbDatabaseQuery, uuid } from '../app/utils.js'
import { colors as metaColors, fonts as metaFonts, fontSpecifier as metaFontSpecifier } from '../app/meta.js'
import {isMap, isSym} from '../../lib/utils.js'
import { Buffer } from 'buffer'
import { LVector } from '../../lib/mal/types.mjs'

export {
  text, color, edInitialize, getEl, elRect, elAddClass, elRemoveClass, addGlobalKeyboardListener, keyboardHandled, eventHandled,
  getLocalStorage, setLocalStorage, getScreenWidth, getTableData, sanitizeName, uploadAndImport,
  addKeydown, removeKeydown, deleteTableRows, genTableQuery, getAppPublishedStatus, createCertificate, createDistribution,
  createEmailIdentity, distributionDnsEntered, updateConfig, updateMeta, getAppTables, getAppMedia, getAppApis,
  uploadFiles, apiUpdate, recordChanged, themesUpdate, getAppDns, getAppMobile, mobileUpdate, userDnsEntered,
  first, rest, isFunc, isAtom,
}

const first = a => a.length > 0 ? a[0] : false
const rest = a => a.length > 1 ? a.slice(1) : []
const isAtom = a => isSym(a) === true || typeof a === 'string' || typeof a === 'number' || typeof a === 'boolean'
const isFunc = (a, e) => isSym(a) === true && e[a] !== undefined

function text(id) {
  return EditTexts[id] || id
}

function color(id) {
  return EditColors[id] || id
}

function eventHandled($e) {
  $e.handled = true
  $e.stopPropagation()
  $e.preventDefault()
}

function getEl(id) {
  return document.getElementById(id)
}

function elRect(el) {
  return el.getBoundingClientRect()
}

function elAddClass(el, cl) {
  return el.classList.add(cl)
}

function elRemoveClass(el, cl) {
  return el.classList.remove(cl)
}

function keyboardHandled($e) {
  eventHandled($e)
}

function sendClick(id) {
  let el = document.getElementById(id)
  if (!el) console.warn('Could not find element with id', id)
  else el.click()
}

function hasCtrl($e) {
  return $e && ($e.ctrlKey || $e.metaKey)
}

let Listeners = []

function addKeydown(fn) {
  Listeners.unshift(fn)
}

function removeKeydown(fn) {
  Listeners = Listeners.filter(el => el !== fn)
}

function dispatchKey($e) {
  for (let i = 0; i < Listeners.length; i += 1) {
    Listeners[i]($e)
    if ($e.handled) return;
  }
}

function globalKeyboardListener($e) {
  if ($e && $e.handled) return;
  dispatchKey($e)
  if ($e && $e.handled) return;
  switch ($e.key) {
    case '+':
      sendClick('railAdd')
      keyboardHandled($e)
      break;
    case '/':
    case '=':
      sendClick('navFormula')
      keyboardHandled($e)
      break;
    case 'm':
    case 'M':
      sendClick('railMedia')
      keyboardHandled($e)
      break;
    case 'd':
    case 'D':
      sendClick(hasCtrl($e) ? 'navDup' : 'railDatabase')
      keyboardHandled($e)
      break;
    case 'h':
    case 'H':
      sendClick('navHelp')
      keyboardHandled($e)
      break;
    case 'c':
    case 'C':
      sendClick(hasCtrl($e) ? 'navCopy' : 'railConnection')
      keyboardHandled($e)
      break;
    case 'f':
    case 'F':
      sendClick('railFunction')
      keyboardHandled($e)
      break;
    case 'p':
    case 'P':
      sendClick('railProcess')
      keyboardHandled($e)
      break;
    case 'l':
    case 'L':
    case 't':
    case 'T':
      sendClick('railTranslate')
      keyboardHandled($e)
      break;
    case 's':
    case 'S':
      sendClick(hasCtrl($e) ? 'navSave' : 'railSettings')
      keyboardHandled($e)
      break;
    case 'v':
    case 'V':
      if (!hasCtrl($e)) break;
      sendClick('navPaste')
      keyboardHandled($e)
      break;
    case 'x':
    case 'X':
      if (!hasCtrl($e)) break;
      sendClick('navCut')
      keyboardHandled($e)
      break;
    case 'z':
    case 'Z':
      if (!hasCtrl($e)) break;
      sendClick('navUndo')
      keyboardHandled($e)
      break;
    case 'Delete':
    case 'Backspace':
      sendClick('navDelete')
      keyboardHandled($e)
      break;
  }
}

function addGlobalKeyboardListener(edit) {
  window.addEventListener('keydown', globalKeyboardListener.bind(edit))
}

function getAppChanged(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  callServer(ed.apgApg(), 'app-edit-changed.lisp', map)
    .then(res => {
      cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      console.warn(err)
      cb(false)
    })
}

function getAppPages(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  callServer(ed.apgApg(), 'app-edit-pages.lisp', map)
    .then(res => {
      cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      console.warn(err)
      cb(false)
  })
}

function getAppTables(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  ed.loading()
  callServer(ed.apgApg(), 'app-edit-tables.lisp', map)
    .then(res => {
      ed.loaded()
      cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      console.warn(err)
      ed.loaded()
      cb(false)
    })
}

function getAppMedia(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  ed.loading()
  callServer(ed.apgApg(), 'app-edit-media.lisp', map)
    .then(res => {
      ed.loaded()
      cb(res && res.data ? ed.li(res.data) : false)
    })
    .catch(err => {
      console.warn(err)
      ed.loaded()
      cb(false)
    })
}

function getAppDns(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  ed.loading()
  callServer(ed.apgApg(), 'app-edit-dns.lisp', map)
    .then(res => {
      ed.loaded()
      cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      console.warn(err)
      ed.loaded()
      cb(false)
    })
}

function getAppMobile(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  ed.loading()
  callServer(ed.apgApg(), 'app-edit-mobile.lisp', map)
    .then(res => {
      ed.loaded()
      cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      console.warn(err)
      ed.loaded()
      cb(false)
  })
}

function getAppApis(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  ed.loading()
  callServer(ed.apgApg(), 'app-edit-connections.lisp', map)
    .then(res => {
      ed.loaded()
      cb(res && res.data ? ed.li(res.data) : false)
    })
    .catch(err => {
      console.warn(err)
      ed.loaded()
      cb(false)
    })
}

function getAppPublishedStatus(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  callServer(ed.apgApg(), 'app-published-status.lisp', map)
    .then(res => {
      cb(res && res.data ? ed.li(res.data) : false)
    })
    .catch(err => {
      console.warn(err)
      cb(false)
    })
}

function createCertificate(ed, domain, pviewDomain, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('domain', domain)
  map.set('pviewDomain', pviewDomain)
  callServer(ed.apgApg(), 'certificate-manager-create.lisp', map)
    .then(res => {
      cb(res && res.data ? ed.li(res.data) : false)
    })
    .catch(err => {
      console.warn(err)
      cb(false)
    })
}

function createEmailIdentity(ed, domain, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('domain', domain)
  callServer(ed.apgApg(), 'email-identity-create.lisp', map)
    .then(res => {
      cb(res && res.data ? ed.li(res.data) : false)
    })
    .catch(err => {
      console.warn(err)
      cb(false)
    })
}

function createDistribution(ed, domain, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('domain', domain)
  callServer(ed.apgApg(), 'cloudfront-create-distribution.lisp', map)
    .then(res => {
      cb(res && res.data ? ed.li(res.data) : false)
    })
    .catch(err => {
      console.warn(err)
      cb(false)
    })
}

function distributionDnsEntered(ed, domain, distId, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('domain', domain)
  map.set('distributionId', distId)
  callServer(ed.apgApg(), 'cloudfront-distribution-dns.lisp', map)
    .then(res => {
      cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      console.warn(err)
      cb(false)
    })
}

function userDnsEntered(ed, domain, pviewDomain, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('domain', domain)
  map.set('pviewDomain', pviewDomain)
  callServer(ed.apgApg(), 'app-user-dns-entered.lisp', map)
    .then(res => {
      cb(res && res.data ? ed.li(res.data) : false)
    })
    .catch(err => {
      console.warn(err)
      cb(false)
    })
}

function updateConfig(ed, cfg, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('config', isMap(cfg) ? cfg : new Map().fromObj(cfg))
  callServer(ed.apgApg(), 'app-update-config.lisp', map)
    .then(res => {
      if (cb) cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      console.warn(err)
      if (cb) cb(false)
    })
}

function recordChanged(ed, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  callServer(ed.apgApg(), 'app-record-changed.lisp', map)
    .then(res => {
      if (cb) cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      console.warn(err)
      if (cb) cb(false)
    })
}

function edInitialize(ed, store) {
  let railpanel = store.model('railpanel')
  if (railpanel) {
    railpanel.id = ed.local('railid', false)
    railpanel.pinned = ed.local('railpinned', false)
    railpanel.open = railpanel.pinned
  }
  store.setModel('screensizemenu', ed.local('screensizemenu', 'Fit'))
  store.setModel('pagemenu', ed.local('pagemenu', '/home'))
  store.setModel('rolemenu', ed.local('rolemenu', 'visitor'))
  store.setModel('darkmode', ed.local('darkmode', false))
  store.setModel('rtl', ed.local('rtl', false))
  store.setModel('mdagalsize', ed.local('mdagalsize', 'md'))
  store.setModel('mdagaltype', ed.local('mdagaltype', 'i'))

  // TODO.JCL - tell user we couldnt get pages.
  getAppPages(ed, pages => store.setModel('pages', pages ? pages.get('pages') || [] : []))
  // getAppTables(ed, tables => store.setModel('tables', tables ? tables.get('tables') || [] : []))
  getAppChanged(ed, changed => {
    if (isMap(changed) && changed.size > 0 ) {
      store.setModel('editChanged', changed.toObj())
      ed.updateChanged()
    }
  })
  store.setModel('tables', [])
  store.setModel('mediaAdds', { files: [] })
}

function getLocalStorage(key, dflt) {
  let it = localStorage.getItem(key)
  if (it === null && dflt !== undefined && dflt !== null) localStorage.setItem(key, JSON.stringify(dflt))
  return JSON.parse(localStorage.getItem(key))
}

function setLocalStorage(key, val) {
  localStorage.setItem(key, JSON.stringify(val))
}

function getScreenWidth(key) {
  switch (key) {
    case 'SM':
      return 'w-[640px]'
    case 'MD':
      return 'w-[768px]'
    case 'LG':
      return 'w-[1024px]'
    case 'XL':
      return 'w-[1280px]'
    case '2XL':
      return 'w-[1536px]'
    case 'Fit':
    default:
      return 'w-full'
  }
}

function getTableData(ed, name, etag, cb) {
  console.log('getTableData', name)
  dbDatabaseQuery(ed.apgApg(), name)
    .then(res => {
      let exp = res && typeof res === 'string' ? ed.li(`'${res}`) : false
      if (isMap(exp)) cb(name, exp.get('total'), exp.get('count'), exp.get('data'))
      else cb(name, 0, 0, false)
    }).catch(err => {
      console.warn(err)
      cb(name, [])
  })
}

const NotAllowedCh = `~!@#$%^&*()_-+=[]{}'";:.,/?\\`;

function compact(val) {
  if (val && val.indexOf('  ') === -1) return val
  return compact(val ? val.replaceAll('  ', ' ') : '')
}

function sanitizeName(name, noDoubleSpace = true) {
  let it = name.split('').reduce((acc, ch) => {
    if (NotAllowedCh.indexOf(ch) === -1 && ch !== '`') acc.push(ch)
    return acc
  }, []).join('')
  return noDoubleSpace ? compact(it) : it
}

function getUploadSigned(ed, name, type, isBase64) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('name', name)
  map.set('base64', isBase64 === true)
  map.set('type', type)
  return new Promise((res, rej) => {
    callServer(ed.apgApg(), 'app-upload-signed.lisp', map)
      .then(ans => {
        res(ans && ans.status === 200 ? ed.li(`'${ans.data}`) : false)
      }).catch(err => {
        console.warn(err)
        rej(err)
    })
  })
}

const readFile = file => new Promise((res, rej) => {
  if (file?.hasUrl) {
    res(file.dataUrl)
  } else {
    let fr = new FileReader()
    fr.addEventListener('load', () => res(new Int8Array(fr.result)))
    fr.readAsArrayBuffer(file)
  }
})

function uploadFile(ed, file, cb) {
  // console.log('uploadFile', file.name, file.type)
  let name = file.name
  let path = `/mda/${name}`
  let type = file.type
  return new Promise((res, rej) => {
    let tasks = [
      getUploadSigned(ed, path, type, true),
      readFile(file)
    ]
    Promise.all(tasks)
      .then(data => {
        ed.apgApg().sendFile(type, data[0].get('url'), data[1], true)
          .then(ans => {
            if (ans && ans.status !== 200) rej('Failed upload')
            data[0] = false
            data[1] = false
            if (cb) cb(file)
            res(file)
          }).catch(err => {
            rej(err)
        })
      }).catch(err => {
        console.error(err)
        rej(err)
    })
  })
}

function uploadFiles(ed, files, cb) {
  return new Promise((res, rej) => {
    ed.loading()
    let tasks = []
    for (let i = 0; i < files.length; i += 1) {
      tasks.push(uploadFile(ed, files[i], cb))
    }
    Promise.all(tasks)
      .then(ans => {
        ed.loaded()
        res(true)
      }).catch(err => {
        ed.loaded()
        rej(err)
    })
  })
}

function uploadAndImport(ed, name, data, cb) {
  return new Promise((res, rej) => {
    let type = 'application/json'
    let fullname = `${name}-${uuid()}`
    let path = `/var/uploads/${fullname}`
    if (cb) cb('signing')
    getUploadSigned(ed, path, type, false)
      .then(ans => {
        if (ans && ans.get('url')) {
          let content = { name, data }
          let stream = Buffer.from(JSON.stringify(content)).toString('base64')
          if (cb) cb('upload')
          ed.apgApg().sendFile(type, ans.get('url'), stream)
            .then(ans => {
              if (ans && ans.status !== 200) rej('Failed upload')
              else {
                let { id, key } = ed.ekeys()
                let map = new Map()
                map.set('appid', ed.appId())
                map.set('eid', id)
                map.set('eik', key)
                map.set('name', path)
                if (cb) cb('import')
                callServer(ed.apgApg(), 'app-import-data.lisp', map)
                  .then(ans => {
                    if (cb) cb('done')
                    res(ans && ans.status === 200 ? ed.li(`'${ans.data}`) : false)
                  }).catch(err => {
                    if (cb) cb('done')
                    console.warn(err)
                    rej(err)
                })
              }
            }).catch(err => {
              if (cb) cb('done')
              console.error(err)
              rej(err)
          })
        } else {
          if (cb) cb('done')
          res(false)
        }
      }).catch(err => {
        if (cb) cb('done')
        rej(err)
     })
  })
}

function deleteTableRows(ed, name, data, cb) {
  return new Promise((res, rej) => {
    let { id, key } = ed.ekeys()
    let map = new Map()
    map.set('appid', ed.appId())
    map.set('eid', id)
    map.set('eik', key)
    map.set('name', name)
    map.set('data', LVector.from(data))
    callServer(ed.apgApg(), 'app-database-delete-rows.lisp', map)
      .then(ans => {
        if (cb) cb('done')
        res(ans && ans.status === 200 ? ed.li(`'${ans.data}`) : false)
      }).catch(err => {
        if (cb) cb('done')
        console.warn(err)
        rej(err)
    })
  })
}

function genTableQuery(ed, name, prompt, cb) {
  console.log('genTableQuery', name, prompt)
  return new Promise((res, rej) => {
    let { id, key } = ed.ekeys()
    let map = new Map()
    map.set('appid', ed.appId())
    map.set('eid', id)
    map.set('eik', key)
    map.set('name', name)
    map.set('prompt', prompt)
    callServer(ed.apgApg(), 'app-database-generate-query.lisp', map)
      .then(ans => {
        console.log('genTableQuery - ans', ans)
        let it = ans && ans.status === 200 ? ed.li(`'${ans.data}`) : false
        if (ans && ans.status === 200 && cb) cb(null, it)
        else if (cb) cb(ans, null)
        res(it)
      }).catch(err => {
        if (cb) cb(err, null)
        console.warn(err)
        rej(err)
     })
  })
}

function updateMeta(ed, app, type) {
  if (type === 'themes') {
    let themes = ed.appMg('themes')
    let palette = themes.get('palette')
    let ostyle = metaColors(palette.toObj())
    app.setOStyles(ostyle)
  } else if (type === 'fonts') {
    let themes = ed.appMg('themes')
    let fonts = themes.get('fontFamilies')
    let families = fonts.toObj()
    let fstyle = metaFonts(fonts.toObj())
    app.setFStyles(fstyle)
    let list = [ ... new Set(Object.values(families).flatMap(it => it)) ]
    let spec = metaFontSpecifier(list)
    app.setFonts({ google: { families: spec } })
  } else if (type === 'icons') {
    let themes = ed.appMg('themes')
    let i = themes.get('icons')
    app.setIcons(i)
    ed.apgApg().setIcons(i)
  } else {
      console.log('**** handle updateMeta', type)
  }
}

function apiUpdate(ed, con, conkeys, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('con', con)
  map.set('conkeys', conkeys)
  ed.loading()
  callServer(ed.apgApg(), 'app-update-api.lisp', map)
    .then(res => {
      ed.loaded()
      if (cb) cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      ed.loaded()
      console.warn(err)
      if (cb) cb(false)
    })
}

function themesUpdate(ed, themes, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('themes', themes)
  ed.loading()
  callServer(ed.apgApg(), 'app-update-themes.lisp', map)
    .then(res => {
      ed.loaded()
      if (cb) cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      ed.loaded()
      console.warn(err)
      if (cb) cb(false)
    })
}

function mobileUpdate(ed, mobile, cb) {
  let { id, key } = ed.ekeys()
  let map = new Map()
  map.set('appid', ed.appId())
  map.set('eid', id)
  map.set('eik', key)
  map.set('mobile', mobile)
  ed.loading()
  callServer(ed.apgApg(), 'app-update-mobile.lisp', map)
    .then(res => {
      ed.loaded()
      if (cb) cb(res && res.data ? ed.li(res.data) : false)
    }).catch(err => {
      ed.loaded()
      console.warn(err)
      if (cb) cb(false)
   })
}
