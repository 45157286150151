<script setup>

import { ref, computed } from 'vue';


/* interface */

const props = defineProps({
  p: Object,
});

const emit = defineEmits([
  'selected',
]);

const images = computed(() => {
  return props.p.images ?? props.p?.images ?? [];
});

const size = computed(() => {
  return props.p.size ?? 'md';
});

const query = computed(() => {
  return props.p?.filter ?? '';
});

const type = computed(() => {
  return props.p?.type ?? '';
});


/* filtering */

const filteredImages = computed(() => {
  return images.value.filter(image => {
    return image.src?.toLowerCase()?.includes(query.value?.toLowerCase() ?? '') && (type.value === '' || image.type === type.value);
  });
});


/* pagination */

const page = ref(1);

const sizes = { sm: 50, md: 35, lg: 25, xl: 20 }

const pageSize = computed(() => {
  return sizes[size.value];
});

const totalPages = computed(() => {
  return Math.ceil(filteredImages.value.length / pageSize.value);
});

const paginatedImages = computed(() => {
  return filteredImages.value.slice((page.value - 1) * pageSize.value, page.value * pageSize.value);
});


/* actions */

function onSelected(image) {
  console.log('onSelected', image)
  emit('selected', image);
}

</script>


<template>
  <div>
    <div class="flex items-center justify-center gap-1 my-4">
      <Button :p="{ disabled: page === 1 }" cl="inline-flex py-2.5 px-2.5 mx-1 text-gray-900 focus:outline-none hover:text-white bg-primary-500 rounded-lg" @click="page--">
        <Icon :p="{ src: 'mdi:chevron-left' }" />
      </Button>
      <template v-for="i of totalPages">
        <Button :p="{ disabled: page === i }" cl="text-xs inline-flex py-2.5 px-4 mx-1 text-gray-900 focus:outline-none hover:text-white bg-primary-500 rounded-lg" @click="page = i">
          {{ i }}
        </Button>
      </template>
      <Button :p="{ disabled: page === totalPages }" cl="inline-flex py-2.5 px-2.5 mx-1 text-gray-900 focus:outline-none hover:text-white bg-primary-500 rounded-lg" @click="page++">
        <Icon :p="{ src: 'mdi:chevron-right' }" />
      </Button>
    </div>
    <div class="flex flex-wrap gap-2">
      <div
        v-for="image in paginatedImages" :key="image.url"
        class="aspect-square p-1 bg-transparent hover:bg-gray-100 transition-colors duration-300 rounded-md cursor-pointer"
        :class="{
          'w-[64px]': size === 'sm',
          'w-[128px]': size === 'md',
          'w-[256px]': size === 'lg',
          'w-[512px]': size === 'xl',
        }"
        @click="onSelected(image)">
        <template v-if="image.type === 'i'">
          <img
            :src="image.url"
            alt="image"
            class="w-full aspect-square object-cover object-center rounded-md"
          />
        </template>
        <template v-else>
          <div class="flex items-center justify-center w-full aspect-square bg-gray-100 rounded-md">
            <Icon :p="{ src: { 'a': 'mdi:file-music', 'v': 'mdi:file-video' }[image.type] || 'mdi:file' }" class="text-[40px]" />
          </div>
        </template>
        <div class="text-sm break-all text-center p-1">
          {{ image.src }}
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center gap-1 my-4">
      <Button :p="{ disabled: page === 1 }" cl="inline-flex py-2.5 px-2.5 mx-1 text-gray-900 focus:outline-none hover:text-white bg-primary-500 rounded-lg" @click="page--">
        <Icon :p="{ src: 'mdi:chevron-left' }" />
      </Button>
      <template v-for="i of totalPages">
        <Button :p="{ disabled: page === i }" cl="text-xs inline-flex py-2.5 px-4 mx-1 text-gray-900 focus:outline-none hover:text-white bg-primary-500 rounded-lg" @click="page = i">
          {{ i }}
        </Button>
      </template>
      <Button :p="{ disabled: page === totalPages }" cl="inline-flex py-2.5 px-2.5 mx-1 text-gray-900 focus:outline-none hover:text-white bg-primary-500 rounded-lg" @click="page++">
        <Icon :p="{ src: 'mdi:chevron-right' }" />
      </Button>
    </div>
  </div>
</template>
