import { AComp } from './AComp.js'
import APageShown from './APageShown.vue'
import APageLoading from './APageLoading.vue'
import ALoop from './ALoop.vue'
import ACookies from './ACookies.vue'
import AVideo from './AVideo.vue'
import AAudio from './AAudio.vue'
import Tester from './Tester.vue'
import EThemer from '../edit/components/EThemer.vue'
import EGallery from '../edit/components/EGallery.vue'
import EExpEdit from '../edit/components/EExpEdit.vue'

import { register as registerOthers } from './components/generated/register.js'

export { register }

function register(app) {
  app.component('a-comp', AComp)
  app.component('a-page-shown', APageShown)
  app.component('a-page-loading', APageLoading)
  app.component('a-loop', ALoop)
  app.component('a-cookies', ACookies)
  app.component('a-video', AVideo)
  app.component('a-audio', AAudio)
  app.component('Tester', Tester)
  app.component('EThemer', EThemer)
  app.component('EGallery', EGallery)
  app.component('EExpEdit', EExpEdit)
  registerOthers(app)
}
