<template>
  <div v-for="(e, i) in rest(exp)" class="flex flex-row">
    <div class="flex flex-col bg-white min-w-10 p-1 items-center text-gray-400">
      A{{i + 1}}
    </div>
    <div class="flex flex-col w-full">
      <EExpr :p="{ exp: e, env: p.p.env, idx: i }" @changed="onChanged" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { first, rest, isFunc } from '../utils.js'

import EExpr from './EExpr.vue'

const p = defineProps({ p: { type: Object }, cl: { type: String } })
const emit = defineEmits([ 'changed' ])

const exp = computed(_ => p.p?.exp || [])

const onChanged = $e => {
  exp.value[$e.idx + 1] = $e.exp
  emit('changed', { idx: p.p.idx, exp: exp.value })
}
</script>
