import { createPinia } from 'pinia'
import { tryCompile } from './utils'

export { createApp }

import { createSSRApp, defineComponent, h, markRaw, reactive, ref } from 'vue'
import VueExcelEditor from 'vue3-excel-editor'
import PageShell from './PageShell.vue'
import { setPageContext } from './usePageContext'
import { createAppeggio } from '../app/Appeggio'
import { setAppeggio } from '../app/useAppeggio.js'

async function createApp(pageContext) {
  const { Page } = pageContext
  const pageRef = ref(markRaw(Page))
  const PageWithShell = defineComponent({
    render() {
      return h(PageShell, {}, { default: () => h(pageRef.value) })
    }
  })

  const app = createSSRApp(PageWithShell)

  Object.assign(app, {
    getAppeggio: () => apg,
    changePage: (pageContext) => {
      Object.assign(pageContextReactive, pageContext)
      pageRef.value = markRaw(pageContext.Page)
      apg.pageChange()
    }
  })

  // First requested page needs to be compiled.
  if (pageContext.tpl && !pageContext.tpljs) {
    pageContext.tpljs = tryCompile(pageContext.tpl)
  }

  const store = createPinia()
  app.use(store)

  app.use(VueExcelEditor)

  const pageContextReactive = reactive(pageContext)
  setPageContext(app, pageContextReactive)

  const apg = createAppeggio(app, pageContextReactive)
  setAppeggio(app, apg)
  apg.registerComponents(app)
  await apg.initialize()

  // console.log('app - locale', pageContext.slocale)

  return app
}
