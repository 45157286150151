<template>
  <div :class="p.cl">
    <EExpr v-if="a.inBrowser()" :p="{ exp, env: a.lispenv() }" @changed="onChanged" />
    <Button cl="mt-4 py-1.5 px-4 mx-2 text-sm rounded-lg bg-primary-500 text-white" @click="onTest">Test function</Button>
    <pre v-if="ans !== false" class="mt-6">
      {{ans}}
    </pre>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useAppeggio } from '../../app/useAppeggio.js'

import EExpr from './EExpr.vue'
import { PRINT } from '../../../lib/mal/mal.mjs'
import EDivider from './EDivider.vue'
import EPrimaryBtn from './EPrimaryBtn.vue'

const a = useAppeggio()
// import { useEdit } from '../useEdit'
// const e = useEdit()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [ String, Array ] } })
const emit = defineEmits(['update:modelValue'])

const s = computed(_ => p.p?.src || p.modelValue || '')
const exp = ref(false)
const ans = ref(false)

const onChanged = $e => {
  console.log('EExpEdit - onChanged', PRINT(exp.value))
  ans.value = false
  if (p.modelValue !== undefined) emit('update:modelValue', PRINT(exp.value))
}

const onTest = _ => {
  ans.value = a.li(exp.value)
}

const setupExp = s => {
  if (Array.isArray(s)) exp.value = s
  else if (typeof s === 'string') exp.value = a.li(`'${s}`) // Only using 'a' so we can test - should be 'e'
}

watch(s, v => {
  if (v) setupExp(v)
}, { immediate: true })
</script>
