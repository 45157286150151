<template>
  <label class="inline-flex items-center cursor-pointer" :class="dsbld ? ' cursor-not-allowed opacity-50' : ''">
    <input ref="r" type="radio" :name="i" v-model="m" :value="v" :class="a.c(p.cl)" :disabled="dsbld">
    <slot></slot>
  </label>
</template>
<script setup>
import { ref, computed, onMounted, onUpdated } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue', 'checked', 'unchecked'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const i = p.p?.id ? p.p.id : 'radio' + p.p?.aid
const v = p.p?.value !== undefined ? p.p.value : p.p?.aid
const r = ref()
const m = computed({
  get()  { return p.modelValue },
  set(a) { 
    emit('update:modelValue', a)
    r.value.setAttribute('checked', a === v)
    emit(a === v ? 'checked' : 'unchecked') 
  }
})
onMounted(_ => {
  if (m.value !== undefined || v !== undefined) {
    r.value.setAttribute('checked', m.value === v)
    emit(m.value === v ? 'checked' : 'unchecked')
  }
})
const isDsbld = _ => (p.p?.disabled !== undefined && p.p?.disabled !== false) 
const dsbld = ref(isDsbld())
onUpdated(_ => {
  dsbld.value = isDsbld()
})
</script>