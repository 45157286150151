
export { EditTexts }

const EditTexts = {
  CloseTip: 'Close',
  PinTip: 'Pin panel to be open all the time',
  UnPinTip: 'Un pin panel so it closes after selection',
  PageTip: 'Open page',
  NewPageTip: 'New page',
  UndoTip: 'Undo',
  CutTip: 'Cut',
  CopyTip: 'Copy',
  PasteTip: 'Paste',
  DuplicateTip: 'Duplicate',
  DeleteTip: 'Delete',
  SelectTip: 'Select (Esc)',
  TextTip: 'Add Text',
  ButtonTip: 'Add Button',
  ImageTip: 'Add Image',
  DropdownTip: 'Add Dropdown',
  ListTip: 'Add List or list item',
  InputTip: 'Add Input field',
  FontTip: 'Font',
  FontSizeTip: 'Font size',
  FontWeightTip: 'Font weight',
  LineHeightTip: 'Line height',
  FillTip: 'Fill color',
  ColorTip: 'Text color',
  DarkTip: 'Go dark mode',
  LightTip: 'Go light mode',
  RtlTip: 'Go right to left mode',
  LtrTip: 'Go left to right mode',
  FitTip: 'Screen size',
  RoleTip: 'Assume a role',
  AlignTip: 'Alignment',
  LineTip: 'Spacing',
  FormulaTip: 'Formula or prompt',
  SaveTip: 'Save your changes',
  HelpTip: 'See how-to and other guides',
  PreviewTip: 'Preview your application',
  PublishTip: 'Publish your application',
  AddPartTip: 'Add a part to the page',
  MediaTip: 'Manage media or add media to the page',
  DatabaseTip: 'Manage databases or add data to the page',
  ConnectionTip: 'Manage APIs or add an API to the page',
  FunctionTip: 'Manage functions or add a function to the page',
  ProcessTip: 'Manage processes or add an process to the page',
  TranslateTip: 'Manage language bundles',
  SettingsTip: 'Manage your Applications settings',
  HelpNav: 'Help',
  SaveNav: 'Save',
  PreviewNav: 'Preview',
  PublishNav: 'Publish',
  PublishNextNav: 'Continue',
  UpdateNav: 'Update',
  ExportNav: 'Export',
  ExportTableTitle: 'Export database (table)',
  ExportTableSubtitle: 'Enter a filename for the export file and select the export format',
  UpdatePreviewTitle: 'Update preview?',
  UpdatePreviewSubtitle: 'You have made changes. Do you want to update your preview version with these changes first?',
  UpdatingPreviewTitle: 'Updating preview',
  UpdatingPreviewSubtitle: 'Your changes are being applied. It can take up to 30 seconds depending on how many database tables are required.',
  UpdatingPreviewClickTitle: 'Preview ready. Click Open to show your preview',
  UpdatingPreviewClickSubtitle: 'Your Browser is blocking pop-ups. Please change it so we can automatically show your preview.',
  UpdatingPreviewClickSubsubtitle: 'Until pop-ups are allowed you need to click Open',
  UpdatingPreviewClickNav: 'Open',
  PublishTitle: 'Publish your application?',
  PublishingTitle: 'Publishing your application...',
  PublishGetStatus: 'Getting published status of your application...',
  PublishSubtitle: 'publish instructions',
  PublishPreviewFirst1: 'You have made changes. Do you want to update your Preview version with these changes first?',
  PublishPreviewFirst2: 'It is recommended that you update Preview first.',
  PublishPreviewFirst3: 'If you choose to continue your application will be published with the version that is currently in Preview.',
  PublishPreviewUpdateNav: 'Update Preview',
  PublishStateIntro1: 'There are several required steps when publishing your application.',
  PublishStateIntro2: 'Some steps need to be done once while others need to be done each time you publish.',
  PublishStateIntro3: 'The following are the steps so you can prepare before continuing.',
  PublishStateIntro4: 'When ready to Publish choose Continue.',
  PublishStateCCert1: 'We are going to create a security Certificate for your application.',
  PublishStateCCert2: 'This Certificate is used by a Web Browser for secure HTTPS / SSL data exchange with your application.',
  PublishStateCCert3: 'It is tied to your Domain name and we need to confirm what that Domain is for your application?',
  PublishStateCCert4: 'Please enter the Domain name and choose Continue',
  PublishStateCCertDomainName: 'Domain name',
  PublishStateNoDnsCert1: 'Your certificate is being created.',
  PublishStateNoDnsCert2: 'This can take up to thirty seconds.',
  PublishStateNoDnsCert3: 'We will check the creation status each second.',
  PublishStateNoDnsCert4: '',
  PublishStateVCert1: 'Your Certificate has been created. Now your Domain name needs to be validated.',
  PublishStateVCert2: 'To validate your Domain name you need access your Domain Name Server (DNS) and add records',
  PublishStateVCert3a: 'You need to add records of type CNAME and enter the values above.',
  PublishStateVCert3b: 'Different DNS providers use different names for the CNAME Name. Some use Host, Record Name or something else.',
  PublishStateVCert3c: 'In the Host, Record Name or whatever your provider calls it, you enter the CNAME Name. In the Value field you enter the CNAME Value.',
  PublishStateVCert4: 'When the these entries have been made choose Continue.',
  PublishStateVCert5: 'You can close this panel and return to this step anytime by Publishing again.',
  PublishStateCCdn1: 'A Content Delivery Network (CDN) is being created for your application.',
  PublishStateCCdn2: 'Creation of a CDN can take up to five minutes.',
  PublishStateCCdn3: 'We will check the creation status every minute.',
  PublishStateVCdn1: 'Your CDN has been created.',
  PublishStateVCdn2: 'Waiting for CDN to be ready. This can take up to ten minutes.',
  PublishStateVCdn3: 'We will check the ready status every minute.',
  PublishStateVCdnPollStop1: 'Your CDN is not yet ready',
  PublishStateVCdnPollStop2: 'We will continue to check the ready status every minute.',
  PublishStateVCdnPollStop3: 'You can close this panel and return to this step anytime by Publishing again.',
  PublishStateCdnDns1: 'Your CDN has been created. Now your Web application has to be known to the internet',
  PublishStateCdnDns2: 'To ensure your Web application can be found on the internet you need to add a record to your Domain Name Server (DNS)',
  PublishStateCdnDns3a: 'You need to add a new record of type CNAME and enter the values above.',
  PublishStateCdnDns3b: 'Different DNS providers use different names for the CNAME Name. Some use Host, Record Name or something else.',
  PublishStateCdnDns3c: 'In the Host, Record Name or whatever your provider calls it, you enter the CNAME Name. In the Value field you enter the CNAME Value.',
  PublishStateCdnDns4a: 'You need to add a new record of type A and enter the value above.',
  PublishStateCdnDns5: 'When these entries have been made choose Continue.',
  PublishStateCdnDns6: 'You can close this panel and return to this step anytime by Publishing again.',
  PublishStateSettings1: 'Confirm application settings.',
  PublishStateSettings2: 'We need to confirm some settings that will be used in your published application.',
  ChangeTableTitle: 'Change database (table)',
  ChangeTableSubtitle: 'Choose a column to change or add a new column',
  ContinueNav: 'Continue',
  ChangeNav: 'Change',
  FormulaTitle: 'Enter prompt or command. Press',
  FormulaSubtitle: 'to run or click',
  FormulaResultTitle: 'Result will be shown below',
  FormulaResultTplTitle: 'Result',
  FormulaRunNav: 'Run',
  FormulaAddNav: 'Add to page',
  FormulaClearNav: 'Reset',
  AddPart: 'Add part',
  Media: 'Media',
  MediaImages: 'Images',
  MediaAudio: 'Audio',
  MediaVideo: 'Video',
  MediaDocuments: 'Documents',
  Databases: 'Databases',
  Blank: ' ',
  PageSize: 'Device size',
  AddTableToPage: 'Add to page',
  DatabaseQueryTitle: 'Query data?',
  DatabaseQueryHint: 'Enter a query prompt or expand to build query manually',
  DatabaseQueryRunning: 'Executing your query...',
  DatabaseQueryPrompt: 'Prompt',
  ImportTable: 'Import',
  ExportTable: 'Export',
  ExportTableName: 'Filename',
  ExportTableFormat: 'Format',
  ExportTableFormatExcel: 'Excel',
  ExportTableFormatCsv: 'CSV',
  ExportTableSelectedOnly: 'Only export selected records?',
  DeletingTableRows: 'Deleting selected record(s)...',
  DbFormulaTitle: 'Enter query prompt. Press',
  DbFormulaInst1: 'Select a Column, Condition and Value for your Query. Add more items or press',
  DbFormulaRemove: 'Remove',
  DbFormulaAdd: 'Add item',
  DbFormulaColumn: 'Column',
  DbFormulaCondition: 'Condition',
  DbFormulaValue: 'Value',
  DbFormulaLimit: 'Maximum rows to get',
  DbFormulaOrder: 'Order by column',
  DbFormulaOrderShort: 'Order',
  DbFormulaOrderDir: 'Order direction',
  DbFormulaOrderDirShort: 'Direction',
  DbEditSelect: 'Edit',
  DbEditTip: 'Edit table',
  DbEditMenuUndoTip: 'Undo changes',
  DbEditMenuDelTip: 'Delete selected rows',
  DbEditMenuAddRowTip: 'Add new row',
  DbEditAddRow: 'Add row',
  DbEditAddRowInst: 'Or select a cell and cursor to the bottom of the table.',
  DbEditMenuAddColTip: 'Change or add columns',
  Exporting: 'Exporting table...',
  LoadingTable: 'Loading table...',
  NewTableTitle: 'New Database table?',
  NewTableSubtitle: 'Enter the name of your new table and select Create',
  NewTableInfo: 'After your table is created it will be opened in the Database viewer',
  NewTableNameInvalid: 'A table with that name already exists',
  CreateTableTitle: 'Creating a Database table',
  CreateTableSubtitlePfx: 'Your new Database table',
  CreateTableSubtitleSfx: 'is being created...',
  CreateTableInfo: 'When created your table will then be opened in the Database viewer',
  CloseNav: 'Close',
  CancelNav: 'Cancel',
  CreateNav: 'Create',
  NewConnection: 'New API Connection',
  NewDatabase: 'New Database',
  NewMedia: 'Upload',
  MediaGallery: 'Gallery',
  DatabaseSuffix: 'Database (table)',
  Connections: 'Connections',
  ConnectionSuffix: 'Connection (API)',
  ConnectionCreate: 'Create a new Connection',
  ConnectionEdit: 'Edit your Connection',
  ConnectionTargetA: 'A Connection can be different during development, preview (test) and live',
  ConnectionTargetB: 'Choose the target stage to setup your Connection',
  ConnectionDir: 'Directory',
  ConnectionDirTip: 'Find a Connection in our Directory',
  ConnectionShare: 'Share',
  ConnectionShareTip: 'Share a Connection in our Directory',
  ConnectionService: 'Service',
  ConnectionName: 'Name',
  ConnectionDesc: 'Description',
  ConnectionMethod: 'Method',
  ConnectionUrl: 'URL',
  ConnectionQuery: 'Query Parameters',
  ConnectionSend: 'Test Send',
  ConnectionResponse: 'Test response shown here',
  New: 'New',
  Functions: 'Functions',
  Processes: 'Processes',
  Translate: 'Languages',
  Settings: 'Settings',
  PageSelect: 'Select',
  FontSelect: 'Font',
  FontSizeSelect: 'Fs',
  RoldeSelect: 'Role',
  PagePublic: 'Public pages',
  PagePublicTip: 'Viewable by everyone',
  PageProtected: 'Protected pages',
  PageProtectedTip: 'Viewable by people in a specific role',
  SigningFile: 'Securing file...',
  UploadingFile: 'Uploading file...',
  WritingUpdates: 'Writing updates...',
  ImportingFile: 'Importing file...',
  LoadingImportedData: 'Loading imported data...',
  ApplicationTitle: 'Application',
  SettingsGeneral: 'General',
  SettingsEmail: 'Emails',
  ThemeTitle: 'Theme',
  SettingsFont: 'Fonts',
  SettingsColor: 'Colors',
  WebsiteTitle: 'Website',
  SettingsWebsite: 'General, SEO and DNS',
  SettingsIcons: 'Icons',
  MobileTitle: 'Mobile app',
  SettingsIos: 'Apple iOS',
  SettingsAndroid: 'Android',
  SettingSocial: 'Application social links',
  SettingSocialInst: 'These links can be managed here and referenced in app',
  SettingSocialYoutube: 'Youtube',
  SettingSocialFacebook: 'Facebook',
  SettingSocialLinkedIn: 'LinkedIn',
  SettingSocialTwitter: 'Twitter',
  SettingSocialInstagram: 'Instagram',
  SettingAppTestEmails: 'Test application Email addresses',
  SettingAppTestEmailsInst: 'Your application can use various Email addresses. The ones below are used during Development and Preview',
  SettingAppEmails: 'Application Email addresses (used when Live/Published)',
  SettingAppEmailsInst: 'Your application can use various Email addresses. The ones below are used when Live / Published.',
  SettingAppEmailContact: 'Contact Us',
  SettingAppEmailSupport: 'Support',
  SettingAppEmailSales: 'Sales',
  SettingAppEmailBilling: 'Billing',
  SettingAppEmailFrom: 'Generic \'From\'',
  SettingsThemeColorInst: 'Select a tab for the color set you would like to change.',
  SettingsThemeFontInst: 'Select a set of fonts for your application.',
  SettingsAppGeneralInst: 'General global settings for your Application.',
  SettingAppWebOnly: 'Publish Mobile applications',
  SettingAppWebOnlyInst: 'Publishing as a Website only or as a Website, iPhone and Android apps does not change your pricing plan.',
  SettingAppDeploy: 'Publish mobile',
  SettingAppUsers: 'Application Users',
  SettingAppUsersInst: 'Your application can have Users who sign up and have different \'Home\' pages based on their role.',
  SettingAppHasSignUps: 'Has User Sign up (Registration)',
  SettingAppHasPrivDash: 'Has User Private home (Dashboard)',
  SettingAppHasAdminDash: 'Has Admin User home (Dashboard)',
  SettingAppModes: 'Application display modes',
  SettingAppHasDarkMode: 'Has Dark mode',
  SettingAppHasRtlMode: 'Has Right to Left (RTL) language mode',
  SettingsWebGeneralInst: 'General Website settings for your application.',
  SettingsWebIconInstA: 'Select a set of icons for your application',
  SettingsWebIconInstB: 'Icons are used in your Website and for mobile device bookmarks',
  SettingsWebIconInstC: 'To create all the icons below from a single image we recommend using this tool',
  SettingsWebIconInstD: 'Fav',
  SettingsWebIconInstE: 'Select an Icon card to change the icon',
  SettingsMobIosInst: 'iOS settings for your application.',
  SettingsMobAndroidInst: 'Android settings for your application.',
  SettingMobAndReleaseInfo: 'These settings are shared with the iOS mobile configuration',
  SettingMobIosReleaseInfo: 'These settings are shared with the Android mobile configuration',
  SettingMobContactInfo: 'Contact Information',
  SettingMobFirstName: 'First name',
  SettingMobLastName: 'Last name',
  SettingMobPhone: 'Phone number',
  SettingMobReviewEmail: 'Review email',
  SettingMobCopyright: 'Copyright text',
  SettingMobTvPolicy: 'Tv Privacy Policy',
  SettingMobInfoUrls: 'Submission informational URL\'s',
  SettingMobPrivacyUrl: 'Privacy Policy URL',
  SettingMobSupportUrl: 'Support URL',
  SettingMobMarketingUrl: 'Marketing URL',
  SettingMobStoreInfo: 'Store Information',
  SettingMobStoreInfoInst: 'Information used to fill your application\'s info in the App Store',
  SettingMobReleaseInfo: 'Release information',
  SettingMobProm: 'Promotional text',
  SettingMobProm2: 'Additional promotional text',
  SettingMobReleaseNotes: 'Release notes',
  SettingMobPrimaryCat: 'Primary category',
  SettingMobPrimaryCatFirstSub: 'Primary First sub-category',
  SettingMobPrimaryCatSecondSub: 'Primary Second sub-category',
  SettingMobSecondaryCat: 'Secondary category',
  SettingMobSecondaryCatFirstSub: 'Secondary First sub-category',
  SettingMobSecondaryCatSecondSub: 'Secondary Second sub-category',
  SettingMobBuildInfo: 'Build Version',
  SettingMobBuildInfo2: 'These are for the next application build',
  SettingMobBuildNumber: 'Number',
  SettingMobBuildVersion: 'Version',
  SettingMobBuildTestNumber: 'Test Number',
  SettingMobBuildTestVersion: 'Test Version',
  SettingMobAppId: 'Application identifier',
  SettingsWebIconFav16A: 'Favourite Icon; used in browser tabs - small screens',
  SettingsWebIconFav16B: 'Preferred size is 16 x 16 with a type of png',
  SettingsWebIconFav32A: 'Favourite Icon; used in browser tabs',
  SettingsWebIconFav32B: 'Preferred size is 32 x 32 with a type of png',
  SettingsWebIconSafariA: 'Icon; used in Safari browser tabs',
  SettingsWebIconSafariB: 'A scalable vector image of type svg',
  SettingsWebIconAppleTouchA: 'Icon; used on iOS devices as a bookmark (home screen)',
  SettingsWebIconAppleTouchB: 'Preferred size is 180 x 180 with a type of png',
  SettingsWebIconChromeA: 'Icon; used on Android devices - small screens - as a bookmark (home screen)',
  SettingsWebIconChromeB: 'Preferred size is 192 x 192 and a type of png',
  SettingsWebIconChromeLgA: 'Icon; used on Android devices - as a bookmark (home screen)',
  SettingsWebIconChromeLgB: 'Preferred size is 512 x 512 with a type of png',
  SettingWebsite: 'Website (URL)',
  SettingSiteImage: 'Site image',
  SettingSiteImageInst: 'An image 1200x800 that represents your Site. Show in search results.',
  SettingSiteImageSize: 'Image below is shown at a smaller (800x600) size',
  SettingDomain: 'Domain name (without www.)',
  SettingName: 'Application name',
  SettingCompany: 'Company title',
  SettingCompanyShort: 'Company short title',
  SettingDescription: 'Description',
  SettingDescriptionShort: 'Short Description',
  SettingSeoInst: 'Each page has it\'s own Title, Description and Keywords. Below are the defaults',
  SettingTitleTemplate: 'Browser tab Title template',
  SettingKeywords: 'Keywords',
  SettingGoogleAnalyticsId: 'Google Analytics ID',
  SettingGoogleSiteId: 'Google Site ID',
  SettingGoogleMapsId: 'Google Maps ID',
  SettingDnsInst: 'DNS (Domain Name Server) entries',
  SettingDnsAlert: 'DNS entries will be shown here after your first publish!',
  TestExpr: 'Test function',
}
