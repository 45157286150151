<template>
  <EExpFunc v-if="isFunc(first(exp), p.p.env)" :p="{ exp, env: p.p.env, idx: p.p.idx }" @changed="onChanged" />
  <EExpAtom v-else-if="isAtom(exp)" :p="{ exp, env: p.p.env, idx: p.p.idx  }" @changed="onChanged" />
  <div v-else>
    Unknown
<!--    <pre>{{exp}}</pre>-->
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { first, isFunc, isAtom } from '../utils.js'

import EExpFunc from './EExpFunc.vue'
import EExpAtom from './EExpAtom.vue'

const p = defineProps({ p: { type: Object }, cl: { type: String } })
const emit = defineEmits([ 'changed' ])

const exp = computed(_ => p.p?.exp || [])

const onChanged = $e => {
  emit('changed', $e)
}
</script>
