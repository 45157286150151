<template>
  <a v-if="h" v-bind="a.p(p.p)" :class="a.c(dcl(p.cl))" :target="p.p.target ? p.p.target : undefined" :disabled="dsbld" :data-tooltip-target="tt" :data-tooltip-placement="tp" :data-drawer-target="drawert" :data-drawer-toggle="drawert" :data-popover-target="popi" @click="download">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
  </a>
  <button v-else ref="r" type="button" v-bind="a.p(m(p.p))" :class="a.c(dcl(p.cl))" :href="h" @click="clicked" :disabled="dsbld" :data-tooltip-target="tt" :data-tooltip-placement="tp" :data-dropdown-toggle="d" :data-dropdown-trigger="dt" :data-dropdown-offset-distance="o" data-dropdown-offset-skidding="0" :data-drawer-target="drawert" :data-drawer-toggle="drawert" :data-popover-target="popi" data-modal-backdrop-classes="z-110" :data-dial-toggle="spdi" :data-active="isButtonActive" @keydown="keydown">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
    <Icon v-if="d && !p.p?.noChevron" cl="transition-all ms-1" :class="{ 'rotate-180': isDropdownOpened }" :p="{ src: 'mdi-chevron-down' }"/>
  </button>
</template>
<script setup>
import { ref, computed, onMounted, onUpdated, inject } from 'vue'
import { useEventListener } from '../../composables/use-event-listener.js'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const h = p.p?.type === 'submit' || p.p?.modal || p.p?.ddid || p.p?.drawert ? undefined : p.p?.href
const tt = p.p?.tipid ? p.p?.tipid : undefined
const tp = p.p?.tipplacement ? p.p?.tipplacement : undefined
const popi = p.p?.popi ? p.p?.popi : undefined
const spdi = p.p?.spdi ? p.p?.spdi : undefined
const d = p.p?.ddid ? p.p.ddid + 'Dd' : undefined
const dt = d && p.p?.hover ? 'hover' : undefined
const o = p.p?.ddid && p.p?.ddoffset !== undefined ? p.p.ddoffset : p.p?.ddid ? '2' : undefined
const r = ref()
const isDropdownOpened = ref(false)
const isDsbld = _ => (p.p?.disabled !== undefined && (p.p?.disabled !== false && p.p?.disabled !== 'Yes' && p.p?.disabled !== 'yes')) 
const dsbld = ref(isDsbld())
const dcl = cl => isDsbld() ? cl + ' cursor-not-allowed opacity-50' : cl 
const dl = p.p?.href && p.p?.download === true ? true : undefined 
const dlh = p.p?.href && p.p?.download === true ? p.p.href : false 
const drawert = p.p?.drawert ? p.p.drawert : undefined
const hasmodal = p.p?.modal !== undefined
const m = v => {
  if (!v) return v;
  if (v && !v.modal) return v;
  let o = { href: undefined, modal: undefined, toggle: undefined } 
  o['data-modal-target'] = v.modal
  if (v.toggle) o['data-modal-toggle'] = v.modal
  if (v.show) o['data-modal-show'] = v.modal
  if (v.hide) o['data-modal-hide'] = v.modal
  return Object.assign(v, o)
}
const download = $e => {
  if (!dl) return;
  $e.preventDefault()
  $e.stopPropagation()
  $e.handled = true
  a.downloadFile(p.p?.href, p.p?.target)
}
const keydown = $e => {
  if ($e.handled) return;
  if ($e.key === 'Escape') {
    $e.handled = true
    r.value.click()
  }
}
onMounted(_ => {
  if (drawert !== undefined) a.fbInit('drawers')
  if (popi !== undefined) a.fbInit('popovers')
  if (hasmodal !== undefined) a.fbInit('modals')
  if (spdi !== undefined) a.fbInit('dials')
})
onUpdated(_ => {
  dsbld.value = isDsbld()
})
useEventListener('click', _ => {
  if (d) {
    const element = document.getElementById(d);
    if (element) {
      isDropdownOpened.value = !element.classList.contains('hidden');
    }
  }
})
const buttonGroupModel = inject('buttonGroupModel', false)
const isButtonActive = computed(() => {
  if (p.p?.value && p.p?.value === buttonGroupModel?.value) {
    return true
  }
})
const clicked = $e => {
  if (p.p?.value && buttonGroupModel) {
    buttonGroupModel.value = p.p.value
  }
}
</script>