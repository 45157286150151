<template>
  <div v-if="isFunc(first(exp), p.p.env)" class="flex flex-row w-full flex-wrap border-l border-t border-gray-200 rounded-tl-lg rounded-tr-lg">
    <div class="flex flex-row w-full bg-gray-200 items-center rounded-tl-lg rounded-tr-lg">
      <div class="flex flex-col ps-3 font-medium pe-3 text-lg" :class="symk(first(exp)) === '*' ? 'pt-2' : 'py-1'">{{symk(first(exp))}}</div>
      <div class="flex flex-col text-sm text-gray-700">Function</div>
    </div>
    <div class="flex flex-col w-full">
      <EExpArgs :p="{ exp, env: p.p.env, idx: p.p.idx }" @changed="onChanged" />
    </div>
  </div>
  <div v-else>
    Unknown
    <pre>{{exp}}</pre>
  </div>
</template>

<script setup>
import { computed} from 'vue'
import { first, isFunc } from '../utils.js'
import { symk } from '../../../lib/utils.js'

import EExpArgs from './EExpArgs.vue'

const p = defineProps({ p: { type: Object }, cl: { type: String } })
const emit = defineEmits([ 'changed' ])

const exp = computed(_ => p.p?.exp || [])

const onChanged = $e => {
  emit('changed', { idx: p.p.idx, exp: exp.value })
}
</script>
