<template>
  <h1 class="mt-6 ms-10">Testing</h1>
  <div v-if="detailed">
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onHome">Home</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onAbout">About</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onUpdate">Update</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onInvalidate">Invalidate</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onCertificate">Certificate</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onValidatedCheck">Validated</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onDistribution">Distribution</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onDatabase">Database</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onDatabaseWrite">Db write</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onDatabaseUpdate">Db update</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onDatabaseDelete">Db delete</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onDatabaseRead">Db read</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onDatabaseQuery">Db query</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onDatabaseTables">Db tables</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onCreateApp">Create</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onApps">Apps</button>
    <button class="ms-6 text-gray px-5 py-2.5 text-center" @click="onShowAll">Show all</button>
  </div>
  <form class="mx-10" @submit="onSubmit">
    <div class="mb-5 w-full">
      <label for="prompt" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your prompt</label>
      <textarea type="text" id="prompt" @keydown="onKeydown" rows="2" autofocus class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
    </div>
    <!--    <div class="mb-5">-->
    <!--      <label for="temperature" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Temperature</label>-->
    <!--      <input type="text" id="temperature" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />-->
    <!--    </div>-->
    <!--    <div class="mb-5">-->
    <!--      <label for="topp" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Topp</label>-->
    <!--      <input type="text" id="topp" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />-->
    <!--    </div>-->
    <button v-if="!working" type="submit" class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
    <button v-else type="submit" class="mt-2 text-gray bg-gray-700 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Working</button>
  </form>
  <!--  <label v-if="working" class="ms-10 block text-sm font-medium text-gray-900 dark:text-white">Working...</label>-->
  <div v-if="!detailed" class="mt-6 ms-10">
    <label class="block mb-4 text-sm font-medium text-gray-900 dark:text-white">Some prompts to try - click a prompt to add it - then click Submit</label>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt('add a button with the text Magic. Make the button background color blue 600 and the foreground color white')">Add a button with the text Home. Make the button text bold and font size 2xl. Make the button background color blue 600 and the foreground color white</button></p>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt('add a navbar')">add a navbar</button></p>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt('add a page with a navbar in the page header and a footer in the page footer and a sidebar in page left. Add the text Side Bar to the sidebar')">add a page with a navbar in the page header and a footer in the page footer and a sidebar in page left. Add the text Side Bar to the sidebar</button></p>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt('add the text Hello World')">add the text Hello World</button></p>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt('add a page with a form. Add an input label Firstname to the form with a input field')">add a page with a form. Add an input label Firstname to the form with a input field</button></p>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt('add a page with a navbar. Make the navbar like the app-navbar')">add a page with a navbar. Make the navbar like the app-navbar</button></p>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt(`add a page with an excel table with data from 'Bond Characters' ordered by age descending`)">add a page with an excel table with data from 'Bond Characters' ordered by age descending</button></p>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt(`add a page with a navbar in page header, and a footer in page footer and a button. Make the button background color blue 500. Add a Row with three Columns in page content. Make the Row  have a gap of 4. Add an icon with source mdi-truck to the third column`)">add a page with a navbar in page header, and a footer in page footer and a button. Make the button background color blue 500. Add a Row with three Columns in page content. Make the Row  have a gap of 4. Add an icon with source mdi-truck to the third column</button></p>
    <p><button class="ms-6 mb-2 text-gray text-left" @click="onPrompt(`add a page with an excel table with data from 'Orders' joined with 'Order Items' on 'orderItems' and 'orders'`)">add a page with an excel table with data from 'Orders' joined with 'Order Items' on 'orderItems' and 'orders'</button></p>
  </div>
  <div v-if="detailed && reply" class="mt-6 mx-10">
    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">The reply</label>
    <pre>
      {{reply}}
    </pre>
  </div>
  <div v-if="detailed && pretpl" class="mt-6 mx-10">
    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Preprocessed</label>
    <p>
      {{pretpl}}
    </p>
  </div>
  <div v-if="detailed && tpl" class="mt-10 mx-10">
    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">The template</label>
    <p>
      {{tpl}}
    </p>
  </div>
  <div v-if="tpl" class="mt-10 mx-6 pb-20">
    <a-comp v-bind='{ apg, tpl }' />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { navigate } from 'vike/client/router'
import { useAppeggio } from './useAppeggio.js'
import {
  genFromPrompt,
  gentplAll,
  cfInvalidatePaths,
  sendDemoshare,
  cmCreateCertificate,
  cmCertificateValidatedCheck,
  cfCreateDistribution,
  dbCreateDatabase,
  dbDatabaseWrite,
  dbDatabaseDelete,
  dbDatabaseRead,
  dbDatabaseQuery,
  dbDatabaseTables,
  gentplFromFileToFile,
  uuid,
  createApp,
  getApps,
} from './utils'

const apg = useAppeggio()
const detailed = ref(false)
const working = ref(false)
const reply = ref(false)
const tpl = ref(false)
const pretpl = ref(false)

const onKeydown = e => {
  if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') onSubmit(e);
}

const onUpdate = e => {
  console.log('onUpdate')
  // gentplAll(apg, 'lassiappeggio-com.appeggio.com')
  gentplAll(apg, 'dev-appeggio-com.appeggio.com')
  // gentplAll(apg, 'dev-checkmyloan-com-au.appeggio.com')
  // gentplAll(apg, 'dev-chronofinance-com-au.appeggio.com')
  // gentplAll(apg, 'dev-aportio-com.appeggio.com')
  // gentplAll(apg, 'dev-skeleton-com.appeggio.com')
  // gentplAll(apg, 'dev-thefintree-com.appeggio.com')
  // gentplAll(apg, 'dev-twiggsnmortar-com.appeggio.com')
  // gentplAll(apg, 'dev-workplacecuntometer-com.appeggio.com')
  // gentplAll(apg, 'dev-servicesalestool-com.appeggio.com')
}

const onInvalidate = e => {
  console.log('onInvalidate')
  // cfInvalidatePaths(apg, 'lassiappeggio-com.appeggio.com', [ 'tpl' ])
  cfInvalidatePaths(apg, 'dev-appeggio-com.appeggio.com', [ 'tpl' ])
  // cfInvalidatePaths(apg, 'dev-checkmyloan-com-au.appeggio.com', [ 'tpl' ])
  // cfInvalidatePaths(apg, 'dev-chronofinance-com-au.appeggio.com', [ 'tpl' ])
  // cfInvalidatePaths(apg, 'dev-aportio-com.appeggio.com', [ 'tpl' ])
  // cfInvalidatePaths(apg, 'dev-skeleton-com.appeggio.com', [ 'tpl' ])
  // cfInvalidatePaths(apg, 'dev-thefintree-com.appeggio.com', [ 'tpl' ])
  // cfInvalidatePaths(apg, 'dev-twiggsnmortar-com.appeggio.com', [ 'tpl' ])
  // cfInvalidatePaths(apg, 'dev-workplacecuntometer-com.appeggio.com', [ 'tpl' ])
  // cfInvalidatePaths(apg, 'dev-servicesalestool-com.appeggio.com', [ 'tpl' ])
}

const onCertificate = e => {
  console.log('onCertificate')
  cmCreateCertificate(apg, 'nextappeggio.com')
}

const onValidatedCheck = e => {
  console.log('onValidatedCheck')
  cmCertificateValidatedCheck(apg, 'nextappeggio.com')
}

const onDistribution = e => {
  console.log('onDistribution')
  cfCreateDistribution(apg, 'nextappeggio.com')
}

const onDatabase = e => {
  console.log('onDatabase')
  dbCreateDatabase(apg, 'Bond Characters')
  dbCreateDatabase(apg, 'Orders')
  dbCreateDatabase(apg, 'Order Items')
}

const bondCharacters = _ => {
  let row1 = new Map()
  row1.set('firstname', 'James')
  row1.set('lastname', 'Bond')
  row1.set('isHero', true)
  row1.set('age', 45)
  let row2 = new Map()
  row2.set('firstname', 'Dr')
  row2.set('lastname', 'Evil')
  row2.set('isHero', false)
  row2.set('age', 55)
  let row3 = new Map()
  row3.set('firstname', 'Ms')
  row3.set('lastname', 'Moneypenny')
  row3.set('isHero', true)
  row3.set('age', 42)
  let row4 = new Map()
  row4.set('firstname', 'Hugo')
  row4.set('lastname', 'Drax')
  row4.set('isHero', false)
  row4.set('age', 60)
  let row5 = new Map()
  row5.set('firstname', 'Rosa')
  row5.set('lastname', 'Klebb')
  row5.set('isHero', false)
  row5.set('age', 63)
  dbDatabaseWrite(apg, ['Bond Characters', [row1, row2, row3, row4, row5]]) // , 'Other', [row1, row2]])
}

const orders = _ => {
  let row1 = new Map()
  row1.set('orderItems', uuid())
  let row2 = new Map()
  row2.set('orderItems', uuid())
  let row3 = new Map()
  row3.set('orderItems', uuid())
  let row4 = new Map()
  row4.set('orderItems', uuid())
  let orders = [row1, row2, row3, row4]
  dbDatabaseWrite(apg, ['Orders', orders]) // , 'Other', [row1, row2]])
  return orders
}

const orderItems = orders => {
  let rows = []
  for (let i = 0; i < orders.length; i+= 1) {
    for (let p = 0; p < 3; p += 1) {
      let row = new Map()
      row.set('orders', orders[i].get('orderItems'))
      row.set('sku', `sku-${i}${p}`)
      rows.push(row)
    }
  }
  dbDatabaseWrite(apg, ['Order Items', rows]) // , 'Other', [row1, row2]])
}

const onDatabaseWrite = e => {
  console.log('onDatabaseWrite')
  bondCharacters()
  let it = orders()
  orderItems(it)
}

const onDatabaseUpdate = e => {
  console.log('onDatabaseUpdate')
  let row1 = new Map()
  row1.set('ruid', 'e54025c9-0d34-468a-924f-a9568cb45c2d')
  row1.set('firstname', 'UpdatedJames')
  row1.set('lastname', 'UpdatedBond')
  dbDatabaseWrite(apg, ['Bond Characters', [row1]])
}

const onDatabaseDelete = e => {
  console.log('onDatabaseDelete')
  dbDatabaseDelete(apg, ['Bond Characters', ['940376a0-259a-45d8-bb94-1d30287b22f3']])
}

const onDatabaseRead = e => {
  console.log('onDatabaseRead')
  dbDatabaseRead(apg, ['Bond Characters', ['1aef6e05-aaff-4c10-8e34-3e4cca156e1d', 'e54025c9-0d34-468a-924f-a9568cb45c2d']])
}

const onDatabaseTables = e => {
  console.log('onDatabaseTables ')
  dbDatabaseTables(apg)
}

const onDatabaseQuery = e => {
  console.log('onDatabaseQuery ')
  dbDatabaseQuery(apg, 'Bond Characters')
}

const onCreateApp = e => {
  console.log('onCreateApp')
  createApp(apg)
}

const onApps = e => {
  console.log('onApps')
  getApps(apg, 'james@appeggio.com', 'a1f46344-9e8e-4f8f-a11c-dcfd93d6f34b')
}

const onShowAll = e => {
  console.log('onShowAll', tpl.value)
  sendDemoshare(apg, tpl.value)
  navigate('/showall')
}

const onHome = e => {
  navigate('/')
}

const onAbout = e => {
  navigate('/about')
}

const onPrompt = e => {
  console.log('onPrompt', e)
  let val = document.getElementById('prompt').value
  console.log('val', `|${val}|`)
  val = val !== '' ? `${val}. ${e}` : e
  let it = document.getElementById('prompt')
  it.value = val
}

const onSubmit = e => {
  e.stopPropagation()
  e.preventDefault()
  working.value = true
  reply.value = false
  tpl.value = false
  pretpl.value = false
  let val = document.getElementById('prompt').value
  // let tmp = document.getElementById('temperature').value
  // if (tmp && tmp !== '') {
  //   tmp = Number(tmp)
  // } else {
  //   tmp = undefined
  // }
  // let top = document.getElementById('topp').value
  // if (top && top !== '') {
  //   top = Number(top)
  // } else {
  //   top = undefined
  // }

  // genFromPrompt(apg, val, tmp, top)
  apg.clearModels((k, v) => k && k.indexOf && k.indexOf('/') !== -1)

  genFromPrompt(apg, val, false, false)
      .then(ans => {
        reply.value = decodeURIComponent(ans.get('reply') || '')
        pretpl.value = decodeURIComponent(ans.get('preprocessed') || '')
        tpl.value = decodeURIComponent(ans.get('template') || '')
        working.value = false
      }).catch(err => {
    reply.value = err.toString()
    working.value = false
  })
}

onMounted(() => {
  if (apg.inBrowser() && window.location.search !== '') detailed.value = true;
})
</script>
