import { defineStore } from 'pinia'
import {isArray, objEntries} from '../../lib/utils'
import { getOsInfo } from './utils'

const ClearExclusions = { 'config': true, 'themes': true, }

function clearExcluding(k, v) {
  console.log('store - clearExcluding', k, ClearExclusions[k] === undefined)
  return ClearExclusions[k] === undefined
}

function extractHashAndParams(url) {
  let hash = url.hash || false
  let params = false
  if (hash && hash.indexOf('?') !== -1) {
    let idx = hash.indexOf('?')
    params = hash.substring(idx + 1)
    hash = hash.substring(0, idx)
  }
  return { hash, params }
}

let resizing = false
let resizingIgnored = false

export const useApgStore = defineStore('apgStore', {
  state: () => ({
    demotpl: false,
    models: {},
    loads: 0,
    inload: false,
    pgver: '',
    pgresize: 1,
    nhash: false,
    nparams: false,
    notify: {},
    ddchange: `${Date.now()}`,
    procs: {},
    resizing: false,
    framed: false,
    analyticsId: ''
  }),
  actions: {
    setDemoshare(demotpl) {
      console.log('store - demoshare')
      this.demotpl = demotpl
    },
    getDemoshare() {
      return this.demotpl
    },
    setModel(id, val) {
      this.models[id] = val
    },
    model(id) {
      return this.models[id]
    },
    clearModels(cb = clearExcluding) {
      objEntries(this.models).forEach(([k, v]) => {
        if (cb(k, v)) {
          this.models[k] = undefined
          delete this.models[k]
        }
      })
    },
    isLoading() {
      return this.inload
    },
    loading() {
      this.loads += 1
      this.inload = true
    },
    loaded() {
      this.loads -= 1
      if (this.loads <= 0) {
        this.inload = false
        this.loads = 0
      }
    },
    pageVersion(apg) {
      if (this.pgver !== apg.pageKey()) this.pgver = apg.pageKey();
      return this.pgver
    },
    pageTickle() {
      this.pgresize += 1
    },
    navhash() {
      return this.nhash
    },
    setPageHash(context) {
      let { hash, params } = extractHashAndParams(context?.urlParsed ? context.urlParsed : {})
      this.nhash = hash || false
      this.nparams = params || false
    },
    navparams() {
      return this.nparams
    },
    setNotify(id, cb) {
      this.notify[id] = cb
    },
    getNotify(id) {
      return this.notify[id] || false
    },
    osInfo() {
      if (this.models['osinfo'] === undefined) {
        getOsInfo(v => this.models['osinfo'] = v)
      }
      return this.models['osinfo']
    },
    ddChange() {
      return this.ddchange
    },
    setDdChange(id) {
      this.ddchange = `${id}${Date.now()}`
    },
    proc(name) {
      return this.procs[name]
    },
    setProc(name, proc) {
      this.procs[name] = proc
    },
    pageResized() {
      return this.pgresize
    },
    resized($e) {
      if (resizing) {
        resizingIgnored = true
        return;
      }
      resizing = setTimeout(_ => {
        if (resizingIgnored) {
          this.pgresize += 1
        }
        resizingIgnored = false
        clearTimeout(resizing)
        resizing = false
      }, 250)
      this.pgresize += 1
    },
    googleAnalyticsId() {
      return this.analyticsId
    },
    setGoogleAnalyticsId(id) {
      this.analyticsId = id
    },
    getAnalyticsBooted() {
      return localStorage.getItem('AnalyticsBooted') === 'true'
    },
    setAnalyticsBooted(val) {
      localStorage.setItem('AnalyticsBooted', val === true ? 'true' : 'false')
    },
    isFramed() {
      return this.framed
    },
    setFramed(val) {
      this.framed = val
    }
  }
})
