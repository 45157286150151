<template>
  <div class="flex flex-row w-full items-center pt-1 pe-1 ps-3 min-w-14 bg-white font-medium border-l border-gray-200">
    <div class="flex flex-col w-[200px]">
      <InputField :p="{}" v-model="mexp" cl="me-2 text-sm rounded-lg" @blur="onBlur"/>
    </div>
    <div class="flex flex-col ms-4 text-sm text-gray-600">
      {{type}}
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useAppeggio } from '../../app/useAppeggio.js'
import { isSym, symk, sym } from '../../../lib/utils.js'

const a = useAppeggio()

const p = defineProps({ p: { type: Object }, cl: { type: String } })
const emit = defineEmits([ 'changed' ])

const exp = computed(_ => p.p?.exp || null)

const type = ref('')
const texp = ref('')
const mexp = computed({
  get: _ => texp.value,
  set: v => texp.value = v
})

const coerceType = (e, init) => {
  let it = !isSym(e) ? Number(e) : NaN
  if (!isNaN(it)) type.value = 'Number'
  else if (isSym(e)) type.value = 'Symbol'
  else if (e.toLowerCase() === 'true' || e.toLowerCase() === 'false') type.value = 'Boolean'
  else if (e.startsWith('"') || e.endsWith('"')) type.value = 'Text'
  else type.value = init ? 'Text' : 'Symbol'
}

const val = $e => {
  emit('changed', { idx: p.p.idx, exp: 'Test' })
}

const func = $e => {
  emit('changed', { idx: p.p.idx, exp: a.li(`'(+ 40 2)`) })
}

const onBlur = _ => {
  let it = texp.value
  if (type.value === 'Symbol') it = sym(it)
  else if (type.value === 'Text') it = it.replaceAll('"', '')
  else if (type.value === 'Number') it = Number(it)
  else if (type.value === 'Boolean') it = it.toLowerCase() === 'true'
  let a = isSym(exp.value) ? symk(exp.value) : exp.value
  let b = isSym(it) ? symk(it) : it
  if (a !== b) emit('changed', { idx: p.p.idx, exp: it })
}

watch(exp, v => {
  coerceType(v, true)
  if (type.value === 'Text') texp.value = `"${v}"`
  else if (type.value === 'Symbol') texp.value = symk(v)
  else texp.value = v
}, { immediate: true })

watch(mexp, v => {
  coerceType(v)
}, { immediate: true })
</script>
