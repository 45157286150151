<template>
  <component :is="currentTag" v-bind="a.p(p.p)" :class="[ backgrounds, a.c(p.cl) ]">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
  </component>
</template>
<script setup>
import { useBackgroundImage } from '../../composables/use-background-image.js'
import { useLinkable } from '../../composables/use-linkable.js'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [Object, String, Number, Boolean] } })
const { backgrounds } = useBackgroundImage(p.p)
const { currentTag } = useLinkable(p.p || {})
import { useWatchModel } from '../../composables/use-watch-model.js'
  const emit = defineEmits(['change'])
  const m = useWatchModel(p, emit)

</script>